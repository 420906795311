<template>
  <div class="v-stack h-stretch gap-3">
    <div class="h-stack h-space-between">
      <a class="heading-title-2" style="align-self: center">Expenses</a>
      <button class="add" @click="$router.push('/finance/expenses/add/gear')">
        Add Expense
      </button>
    </div>
    <div class="card light mn">
      <div class="content h-stretch">
        <div class="v-stack h-stretch gap-3">
          <div class="pane-horizontal gap-3">
            <div class="v-stack h-stretch v-start gap-3 card py-5">
              <a>One Time & Reccuring</a>
              <div
                class="pane-horizontal gap-3"
                v-for="category in categories"
                :key="category"
              >
                <div class="text-right">{{ category }}:</div>
                <div class="text-left">
                  {{
                    format.currency(
                      sumCategory(
                        category,
                        oneTimeExpenses.concat(reccuringExpenses)
                      )
                    )
                  }}
                  Kč
                </div>
              </div>
            </div>
            <div class="v-stack h-stretch v-start gap-3 card py-5">
              <a>Other</a>
              <div class="pane-horizontal gap-3">
                <div class="text-right">Gear:</div>
                <div class="text-left">
                  {{ format.currency(totalGearCost) }} kč
                </div>
              </div>
              <div class="pane-horizontal gap-3">
                <div class="text-right">Crew:</div>
                <div class="text-left">{{ format.currency(crewCost) }} kč</div>
              </div>
            </div>
          </div>
          <div class="v-stack h-stretch v-start gap-3 card py-5">
            <a>Sum</a>
            <div class="pane-horizontal gap-3">
              <div class="text-right">OneTime & Reccuring:</div>
              <div class="text-left">
                {{ format.currency(oneTimeExpenseCost + reccuringExpenseCost) }}
                kč
              </div>
            </div>
            <div class="pane-horizontal gap-3">
              <div class="text-right">Other:</div>
              <div class="text-left">
                {{ format.currency(totalGearCost + crewCost) }} kč
              </div>
            </div>
            <div class="separator"></div>
            <div class="pane-horizontal gap-3" style="font-weight: bold">
              <div class="text-right">Total:</div>
              <div class="text-left">
                {{
                  format.currency(
                    totalGearCost +
                      crewCost +
                      oneTimeExpenseCost +
                      reccuringExpenseCost
                  )
                }}
                kč
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h-stack gap-3 h-start v-center">
      <a class="heading-title-2">One Time Expenses</a>
      <label>Category:</label>
      <select v-model="categoryFilterOneTime">
        <option value="">all</option>
        <option
          v-for="category in categoriesOneTime"
          :key="category"
          :value="category"
        >
          {{ category }}
        </option>
      </select>
    </div>
    <div class="card light mn pn">
      <div class="card pa-5">
        <div class="v-stack h-stretch gap-3">
          <div
            class="pane-horizontal gap-3"
            v-for="category in categoriesOneTime"
            :key="category"
          >
            <div class="text-right">{{ category }}:</div>
            <div class="text-left">
              {{ format.currency(sumCategory(category, oneTimeExpenses)) }}
              Kč
            </div>
          </div>
          <div class="separator"></div>
          <div class="pane-horizontal gap-2">
            <div class="text-right">Sum:</div>
            <div class="text-left">
              {{ format.currency(oneTimeExpenseCost) }} Kč
            </div>
          </div>
        </div>
      </div>
    </div>
    <Table
      v-on:rowSelected="$router.push('/finance/expenses/edit/' + $event._id)"
      :items="
        oneTimeExpensesWithoutGear.filter((expense) =>
          categoryFilterOneTime == ''
            ? true
            : expense.category == categoryFilterOneTime
        )
      "
      :defaultColumn="0"
      :limit="10"
      :pageable="true"
      :height="360"
      :columns="[
        {
          name: 'Date',
          sort: 'date',
          path: ['date'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Category',
          sort: 'alphabet',
          path: ['category'],
        },
        {
          name: 'Title',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Amount',
          sort: 'numeric',
          path: ['amount'],
          format: 'czk',
          formatType: 'currency',
        },
        {
          name: 'Store',
          sort: 'alphabet',
          path: ['store'],
        },
        {
          name: 'Description',
          sort: 'alphabet',
          path: ['description'],
        },
      ]"
    ></Table>
    <div class="h-stack gap-3 v-center h-start">
      <a class="heading-title-2">Reccuring Expenses</a>
      <label>Category:</label>
      <select v-model="categoryFilterReccuring">
        <option value="">all</option>
        <option
          v-for="category in categoriesReccuring"
          :key="category"
          :value="category"
        >
          {{ category }}
        </option>
      </select>
    </div>
    <div class="card light mn pn">
      <div class="card pa-5">
        <div class="v-stack h-stretch gap-3">
          <div
            class="pane-horizontal gap-3"
            v-for="category in categoriesReccuring"
            :key="category"
          >
            <div class="text-right">{{ category }}:</div>
            <div class="text-left">
              {{ format.currency(sumCategory(category, reccuringExpenses)) }}
              Kč
            </div>
          </div>
          <div class="separator"></div>
          <div class="pane-horizontal gap-2">
            <div class="text-right">Sum:</div>
            <div class="text-left">
              {{ format.currency(reccuringExpenseCost) }} Kč
            </div>
          </div>
        </div>
      </div>
    </div>
    <Table
      v-on:rowSelected="
        $router.push('/finance/expenses/edit/' + $event.reference)
      "
      :items="
        reccuringExpensesWithoutGear.filter((expense) =>
          categoryFilterReccuring == ''
            ? true
            : expense.category == categoryFilterReccuring
        )
      "
      :defaultColumn="0"
      :limit="10"
      :height="360"
      :pageable="true"
      :columns="[
        {
          name: 'Date',
          sort: 'date',
          path: ['date'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Category',
          sort: 'alphabet',
          path: ['category'],
        },
        {
          name: 'Title',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Store',
          sort: 'alphabet',
          path: ['store'],
        },
        {
          name: 'End Date',
          sort: 'date',
          path: ['reccuringEndDate'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Amount',
          sort: 'numeric',
          path: ['amount'],
          format: 'czk',
          formatType: 'currency',
        },
        {
          name: 'Description',
          sort: 'alphabet',
          path: ['description'],
        },
      ]"
    ></Table>
    <a class="heading-title-1">Gear</a>
    <div class="card light mn pn">
      <div class="card pa-5">
        <div class="pane-horizontal gap-3">
          <div class="text-right">Sum:</div>
          <div class="text-left">{{ format.currency(totalGearCost) }} Kč</div>
        </div>
      </div>
    </div>
    <a class="heading-title-2">Pricelist Gear</a>
    <Table
      v-on:rowSelected="
        $router.push('/pricelist/item/detail/' + $event.item._id)
      "
      :items="gearFiltered"
      :defaultColumn="0"
      :limit="10"
      :height="360"
      :pageable="true"
      :columns="[
        {
          name: 'Date',
          sort: 'date',
          path: ['dateOfPurchase'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Serial Number',
          sort: 'alphabet',
          path: ['serialNumber'],
        },
        {
          name: 'Store Description',
          sort: 'alphabet',
          path: ['storeDescription'],
        },
        {
          name: 'Cost',
          sort: 'numeric',
          path: ['value'],
          format: 'czk',
          formatType: 'currency',
        },
        {
          name: 'Description',
          sort: 'alphabet',
          path: ['description'],
        },
      ]"
    ></Table>
    <a class="heading-title-2">One Time Gear Expenses</a>
    <Table
      v-on:rowSelected="$router.push('/finance/expenses/edit/' + $event._id)"
      :items="oneTimeGearExpenses"
      :defaultColumn="0"
      :limit="10"
      :height="360"
      :pageable="true"
      :columns="[
        {
          name: 'Date',
          sort: 'date',
          path: ['date'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Title',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Store',
          sort: 'alphabet',
          path: ['store'],
        },
        {
          name: 'Amount',
          sort: 'numeric',
          path: ['amount'],
          format: 'czk',
          formatType: 'currency',
        },
        {
          name: 'Description',
          sort: 'alphabet',
          path: ['description'],
        },
      ]"
    ></Table>
    <a class="heading-title-2">Recurring Gear Expenses</a>
    <Table
      v-on:rowSelected="
        $router.push('/finance/expenses/edit/' + $event.reference)
      "
      :items="reccuringGearExpenses"
      :defaultColumn="0"
      :limit="10"
      :height="360"
      :pageable="true"
      :columns="[
        {
          name: 'Date',
          sort: 'date',
          path: ['date'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Title',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Store',
          sort: 'alphabet',
          path: ['store'],
        },
        {
          name: 'End Date',
          sort: 'date',
          path: ['reccuringEndDate'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Amount',
          sort: 'numeric',
          path: ['amount'],
          format: 'czk',
          formatType: 'currency',
        },
        {
          name: 'Description',
          sort: 'alphabet',
          path: ['description'],
        },
      ]"
    ></Table>
    <a class="heading-title-1">Crew</a>
    <div class="card light mn pn">
      <div class="card pa-5">
        <div class="pane-horizontal gap-3">
          <div class="text-right">Sum:</div>
          <div class="text-left">{{ format.currency(crewCost) }} Kč</div>
        </div>
      </div>
    </div>
    <TableWorkdays
      :workdays="workdaysFilteredWithoutAdmin"
      :oneTimePay="oneTimePayFilteredWithoutAdmin"
      v-on:refresh="() => {}"
      :showName="true"
      :pageable="true"
    ></TableWorkdays>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import TableWorkdays from "@/components/TableWorkdays.vue";
import moment from "moment";
import utils from "@/utils.js";

export default {
  data() {
    return {
      categoryFilterOneTime: "",
      categoryFilterReccuring: "",
      format: utils.format,
    };
  },
  props: {
    period: {
      type: String,
      default: "month",
    },
    month: {
      type: Number,
      default: moment().month() + 1,
    },
    year: {
      type: Number,
      default: moment().year(),
    },
    startDate: {
      type: String,
      default: moment().date(1).toISOString(),
    },
    endDate: {
      type: String,
      default: moment().date(moment().daysInMonth()).toISOString(),
    },
    gearFiltered: {
      type: Array,
      default: () => [],
    },
    workdaysFilteredWithoutAdmin: {
      type: Array,
      default: () => [],
    },
    oneTimePayFilteredWithoutAdmin: {
      type: Array,
      default: () => [],
    },
    reccuringExpenses: {
      type: Array,
      default: () => [],
    },
    oneTimeExpenses: {
      type: Array,
      default: () => [],
    },
    oneTimeExpenseCost: {
      type: Number,
      default: 0,
    },
    totalGearCost: {
      type: Number,
      default: 0,
    },
    reccuringExpenseCost: {
      type: Number,
      default: 0,
    },
    crewCost: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Table,
    TableWorkdays,
  },
  methods: {
    sumCategory(category, expenses) {
      let sum = 0;

      for (const expense of expenses) {
        if (expense.category == category) {
          sum += expense.amount;
        }
      }

      return sum;
    },
  },
  computed: {
    oneTimeExpensesWithoutGear() {
      return this.oneTimeExpenses.filter(
        (expense) => expense.category != "gear"
      );
    },
    reccuringExpensesWithoutGear() {
      return this.reccuringExpenses.filter(
        (expense) => expense.category != "gear"
      );
    },
    oneTimeGearExpenses() {
      return this.oneTimeExpenses.filter(
        (expense) => expense.category == "gear"
      );
    },
    reccuringGearExpenses() {
      return this.reccuringExpenses.filter(
        (expense) => expense.category == "gear"
      );
    },
    categories() {
      return Array.from(
        new Set(
          this.oneTimeExpenses
            .concat(this.reccuringExpenses)
            .map((expense) => expense.category)
        )
      ).filter((category) => category != "gear");
    },
    categoriesOneTime() {
      return Array.from(
        new Set(this.oneTimeExpenses.map((expense) => expense.category))
      ).filter((category) => category != "gear");
    },
    categoriesReccuring() {
      return Array.from(
        new Set(this.reccuringExpenses.map((expense) => expense.category))
      ).filter((category) => category != "gear");
    },
  },
};
</script>

<style scoped>
.h-stack div {
  align-self: center;
}

.h-stack label {
  align-self: center;
}

.h-stack select {
  align-self: center;
  width: 160px;
}

a {
  align-self: center;
  padding-top: 0 !important;
}
</style>
